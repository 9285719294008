



















































































import { VeeValidateObserverRef } from '@/types/libraries/VeeValidateObserverRef';
import { ApiValidationErrorResponse } from '@/types/api/Response';
import { AxiosError } from 'node_modules/axios';
import Vue from 'vue';
export default Vue.extend({
  'name': 'ComponentLogin',
  'computed': {
    isResetPassword () {
      return this.$route.name === 'ResetPassword';
    }
  },
  mounted () {
    if (this.$route.query && this.$route.query.token) {
      this.form.token = this.$route.query.token as string;

      this.getDetailsForCreatePassword();
    }
  },
  data () {
    return {
      'emailDisplay': '',
      'form': {
        'token': '',
        'password': process.env.NODE_ENV !== 'production' ? '12345678' : '',
        'confirmPassword': process.env.NODE_ENV !== 'production' ? '12345678' : ''
      },
      'responseTextError': false,
      'responseText': ''
    };
  },
  'methods': {
    getDetailsForCreatePassword () {
      this.axios.get('email', {
        'params': {
          'token': this.form.token
        }
      }).then(({ data }) => {
        this.emailDisplay = data.result.email;
      });
    },
    onSubmit () {
      this.responseTextError = false;
      this.responseText = '';
      this.axios
        .put('/verify-account', this.form)
        .then(() => {
          this.responseText =
            'Password created successfully! Redirecting to login page in 5 seconds...';

          setTimeout(() => {
            this.$router.push({
              'name': 'Login'
            });
          }, 5000);
        })
        .catch((err: AxiosError) => {
          const { response } = err;

          this.responseTextError = true;
          this.responseText = response?.data?.message;

          if (response && response.status === 422 && response.data.result) {
            const data = response.data.result as ApiValidationErrorResponse;
            console.log(data);
            (this.$refs.form as VeeValidateObserverRef).setErrors(data);
          }
        });
    }
  }
});
